import React from 'react'
import Layout from '../components/layout'

export default function NotFoundPage() {
  return (
    <Layout>
      <div id='main' className='alt'>
        <section id='one'>
          <div className='inner'>
            <h1>Seite nicht gefunden</h1>
          </div>
        </section>
      </div>
    </Layout>
  )
}